<template>
  <div class="container">
    <div class="setSystem" @click="setSystem" v-if="/android/.test(this.ua)">
      <img src="../assets/img/system.png" alt="" />
      <p>系统设置</p>
    </div>
    <!--<div class="logo">-->
    <!--&lt;!&ndash; <img src="@/assets/img/patientend/logo@2x.png" alt="" /> &ndash;&gt;-->
    <!--<div class="left">-->
    <!--<img src="@/assets/img/logo.png" alt="" />-->
    <!--</div>-->
    <!--<div class="right">-->
    <!--<div class="hd">脑健康康复训练系统</div>-->
    <!--<div class="bt">Brain Health Rehabilitation Training System</div>-->
    <!--</div>-->
    <!--</div>-->
    <div class="login-box">
      <div class="hd">
        <span>用户登录</span>
      </div>
      <div class="bt">
        <input
          type="text"
          v-model.trim="loginInfo.username"
          placeholder="请输入手机号"
          ref="phoneRef"
          maxlength="11"
          @input="pureNumber"
          @keyup.enter="burl()"
        />
        <input
          type="password"
          v-model.trim="loginInfo.password"
          placeholder="请输入登录密码"
          @keyup.enter="loginHandler()"
          ref="passd"
        />
        <button class="loginbtn" @click="loginHandler">登录</button>
      </div>
    </div>
    <div class="list-box">
      <patient-modal
        :dataList="patientList"
        @callback="handleModal"
        ref="modalRef"
      ></patient-modal>
    </div>
    <Modal
      v-model="openModal"
      :mask-closable="false"
      class-name="vertical-center-modal modalHead product"
      :footer-hide="true"
      title="产品说明"
    >
      <div>
        <h1>产品名称：认知功能康复训练与评估软件</h1>
        <h1>型号规格：JSNL- KFXL- 01</h1>
        <div>
          <span>发布版本号：V2.0</span
          ><span class="span-space">序列号：202012B01</span>
        </div>
        <h1>生产日期：2020.12.18</h1>
        <h1>使用期限：10年</h1>
      </div>
      <div class="descone">
        <h1>医疗器械注册证编号：</h1>
        <h1>注册人/生产企业名称：湖南京师脑力科技有限公司</h1>
        <h1 class="info-long">
          住所/生产地址：湖南省长沙市天心区创业路159号长沙宝成建材有限公司1栋高层建材加工厂房901-4房
        </h1>
        <h1>联系方式：15901135809</h1>
      </div>
      <div>
        <h1>其他内容详见说明书</h1>
      </div>
    </Modal>
  </div>
</template>

<script>
// import {Form, FormItem} from 'view-design'
import patientModal from "@v/patientend/items/patientList";
import { setCookieToten, setCookiePatient } from "@js/session.sg";
import { Debounce } from "@js/debounce.js";
export default {
  name: "Login",
  data() {
    return {
      openModal: false,
      loginInfo: {
        username: "",
        password: "",
      },
      // todo 临时修改为true
      isModalShow: false,
      patientList: [],
      ua: "",
    };
  },

  components: {
    patientModal,
  },

  computed: {
    isMobile() {
      const regStr = /^1[3|4|5|6|7|8|9][0-9]{9}$/;
      return regStr.test(this.loginInfo.username);
    },
  },
  watch: {
    // loginInfo: {
    //     handler() {
    //         if (this.loginInfo.username.length > 11) {
    //         }
    //     },
    //     deep: true
    // }
  },
  mounted() {
    // 残留监听事件初始赋null
    window.addEventListener("keyup", null);
    window.addEventListener("mousemove", null);
    window.addEventListener("mousedown", null);
    window.addEventListener("mousewheel", null);
    window.addEventListener("touch", null);
    window.addEventListener("touchmove", null);
    localStorage.clear();
    sessionStorage.clear();
    this.ua = navigator.userAgent.toLowerCase();
    // 自动给手机输入框获取焦点
    this.$refs.phoneRef.focus();
    // console.log('moutend username password', this.$route.query.username)
    // console.log('moutend username password', this.$route.query.password)
    if (this.$route.query.username) {
      this.loginInfo.username = this.$route.query.username;
    }
    if (this.$route.query.password) {
      this.loginInfo.password = this.$route.query.password;
    }
  },

  methods: {
    // 点击系统设置
    setSystem() {
      if (/android/.test(this.ua)) {
        window.JSBridge.goSystemSetting();
      }
    },
    // 账号回车事件
    burl() {
      this.$refs.passd.focus();
    },
    // 账号纯数字校验
    pureNumber(event) {
      this.loginInfo.username = event.target.value.replace(/[^\d]/g, "");
      this.loginInfo.username.trim();
    },
    // 打开产品说明弹框
    openProductModal() {
      this.openModal = true;
    },
    loginHandler: Debounce(function () {
      // let regx = new RegExp(/^[A-Za-z0-9]+$/)
      if (this.loginInfo.username === "") {
        this.$Message.warning("请输入登录手机号");
        return;
      }
      if (this.loginInfo.username.length !== 11) {
        this.$Message.warning("该手机号格式不正确");
        return;
      }
      if (this.loginInfo.password === "") {
        this.$Message.warning("请输入登录密码");
        return;
      }
      // if (this.loginInfo.password.length < 4 || this.loginInfo.password.length > 16) {
      //     this.$Message.warning("请输入4-16位数字/字母或组合");
      //     return;
      // }
      // if (!regx.test(this.loginInfo.password)) {
      //     this.$Message.warning("请输入4-16位数字/字母或组合");
      //     return;
      // }
      this.$http
        .post(
          "patient/pc/login",
          this.$http.adornData(this.loginInfo, false, "form")
        )
        .then((res) => {
          console.log("login res", res);
          if (res.code === 1) {
            // todo
            // 存储 access_token 和 refresh_token
            // setCookieToten(res.data);
            this.$store.dispatch("setToken", res.data.access_token);
            sessionStorage.setItem(
              "access_token",
              this.$store.getters.getCurrentToken
            );
            this.getPatientInfo(res);
          } else {
            this.loginInfo.username = "";
            this.loginInfo.password = "";
            this.$Message.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }, 1500),
    // 登录后拿到token，使用token获取用户信息
    async getPatientInfo(loginRes) {
      let res;
      try {
        res = await this.$http.get(
          "patient/info/" + loginRes.data.access_token
        );
        console.log("patientInfo", res);
        if (res.code === 1) {
          setCookiePatient(res.data);
          this.$store.dispatch("setPatInfo", res.data);
          sessionStorage.setItem("patientInfo", JSON.stringify(res.data));
          this.checkByPhone();
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 拿到info后进行check，查看是否有多位用户
    async checkByPhone() {
      let res;
      let data = { phone: this.loginInfo.username };
      try {
        const res = await this.$http.post(
          "patient/detail/checkByPhone",
          this.$http.adornData(data, false, "form")
        );
        if (res.code === 1) {
          if (res.data.patientList.length > 1) {
            this.patientList = res.data.patientList;
            this.$refs.modalRef.showModal();
          } else {
            console.log(res.data);
            console.log("查看当前环境", process.env.NODE_ENV);
            if (process.env.NODE_ENV === "qas") {
              // 添加监控需要标识
              window.localStorage.wmUserInfo = JSON.stringify({
                userId: res.data.patientList[0].patientId,
                userTag: "P",
                projectVersion: "1.0.0",
                env: "sit",
              });
            } else if (process.env.NODE_ENV === "production") {
              // 添加监控需要标识
              window.localStorage.wmUserInfo = JSON.stringify({
                userId: res.data.patientList[0].patientId,
                userTag: "P",
                projectVersion: "1.0.0",
                env: "pro",
              });
            }
            // 直接提示登录成功并跳转
            this.$Message.success("登录成功");
            this.$router.push("/main");
            setTimeout(() => {
              window.location.reload();
            }, 500);
          }
          // console.log("checkRes", res);
        } else {
          this.$Message.error(res.msg);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // check得到多条患者信息 在list中选择一位患者点击登录
    async handleModal(val) {
      sessionStorage.setItem("doctorId", val.doctorId);
      if (val.patientId) {
        let res;
        let arr;
        let dataList = {
          patientId: val.patientId,
        };
        try {
          res = await this.$http.get(
            `patient/getById?patientId=${val.patientId}`
          );
          arr = await this.$http.post(
            "patient/pc/12nrelation",
            this.$http.adornData(dataList, false, "json"),
            this.$http.jsonContent()
          );
          console.log(arr);
          // console.log("selectedInfo", res);
          if (res.code === 1) {
            setCookiePatient(res.data);
            if (process.env.NODE_ENV === "qas") {
              // 添加监控需要标识
              window.localStorage.wmUserInfo = JSON.stringify({
                userId: res.data.patientId,
                userTag: "P",
                projectVersion: "1.0.0",
                env: "sit",
              });
            } else if (process.env.NODE_ENV === "production") {
              // 添加监控需要标识
              window.localStorage.wmUserInfo = JSON.stringify({
                userId: res.data.patientId,
                userTag: "P",
                projectVersion: "1.0.0",
                env: "pro",
              });
            }
            this.$store.dispatch("setPatInfo", res.data);
            sessionStorage.setItem("patientInfo", JSON.stringify(res.data));
            this.$Message.success("登录成功");
            this.$router.push("/main");
            setTimeout(() => {
              window.location.reload();
            }, 500);
          } else {
            this.$Message.error(res.msg);
          }
        } catch (error) {
          console.log(error);
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@css/modalHead.scss";
.container {
  position: relative;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: url("../assets/img/logo-bg.png") no-repeat;
  background-position: center;
  background-size: cover;
  .setSystem {
    position: absolute;
    right: 60px;
    top: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
      margin-right: 10px;
    }
    p {
      font-size: 26px;
      font-weight: 300;
      color: #ffffff;
    }
  }
  .login-box {
    margin-top: 325px;
    width: 500px;
    height: 460px;
    background: #ffffff;
    border-radius: 30px;
    .hd {
      background: #dbeaff;
      border-radius: 30px 30px 0px 0px;
      height: 80px;
      text-align: center;
      line-height: 80px;
      span {
        font-size: 24px;
        font-weight: 600;
        color: #333333;
      }
    }
    .bt {
      border-radius: 0 0 30px 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      input {
        border: none;
        width: 350px;
        height: 48px;
        padding: 14px 18px;
        box-sizing: border-box;
        background: #f4f6f7;
        border-radius: 2px;
        border: 1px solid #dddddd;
        font-size: 18px;
        font-weight: 400;
        outline: none;
        &:first-child {
          margin-top: 70px;
          margin-bottom: 30px;
        }
      }
      input::placeholder {
        font-size: 18px;
        font-weight: 400;
        color: #999999;
      }
      .loginbtn {
        width: 350px;
        height: 54px;
        background: #4174f7;
        border-radius: 8px;
        border: 0;
        font-size: 20px;
        font-weight: 400;
        color: #ffffff;
        cursor: pointer;
        margin-top: 40px;
      }
    }
  }
}
/*产品说明弹框*/
/deep/.product {
  .ivu-modal {
    width: 1000px !important;
    .ivu-modal-body {
      padding: 40px 60px;
      h1,
      div {
        font-size: 20px !important;
        font-weight: 400 !important;
        color: #666666 !important;
        margin-top: 15px;
      }
      .descone {
        margin: 30px 0;
      }
    }
    .span-space {
      margin-left: 10px;
    }
    // .info-long {
    //     line-height: 10px;
    // }
  }
}
</style>
